<template>
	<div class="index" v-loading="loading">

		<HeaderInc ref="ref_header" :info="info"></HeaderInc>

		<div class="login_bg">
			<div class="login">
				<div class="login_box">
					<div class="login_title">
						<h1>评审员注册</h1>
					</div>
					<el-form ref="reg_form" :model="logininfo" :rules="rules" status-icon label-width="80px"
						class="demo-ruleForm">
						<el-form-item label="手机号" prop="phone">
							<el-input v-model="logininfo.phone" type="text" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="验证" prop="login_norebots">
							<SliderCheck ref="login_Check" :successFun="loginSuccess" :errorFun="loginError">
							</SliderCheck>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
							<el-input v-model="logininfo.code" type="text" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="真实姓名" prop="name">
							<el-input v-model="logininfo.name" type="text" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="职务职称" prop="zhiwu">
							<el-input v-model="logininfo.zhiwu" type="text" placeholder="请输入职务职称" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="单位学校" prop="company">
							<el-input v-model="logininfo.company" type="text" placeholder="请输入详细单位学校"></el-input>
						</el-form-item>
						<el-form-item>
							<a class="login_buttton" @click="submitForm('reg_form')">注册</a>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import SliderCheck from '@/components/SliderCheck.vue'
	import HeaderInc from '@/components/HeaderInc.vue'
	export default {
		name: 'Reg',
		components: {
			SliderCheck,
			HeaderInc
		},
		props: {},
		data() {
			const validatephone = (rule, value, callback) => {
				setTimeout(() => {
					const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
					if ((!reg.test(value)) && value != '') {
						callback(new Error('请输入正确的电话号码'));
					} else {
						callback();
					}
				}, 1000)
			}
			return {
				logininfo: {},
				reginfo: {},
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validatephone,
							trigger: 'blur'
						},
					],
					login_norebots: [{
						required: true,
						message: '请过人机滑块验证',
						trigger: 'blur'
					}, ],
					code: [{
						required: true,
						message: '请输入验证码~',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入您的真实姓名',
						trigger: 'blur'
					}],
					zhiwu: [{
						required: true,
						message: '请输入您的职位职务~',
						trigger: 'blur'
					}],
					company: [{
						required: true,
						message: '请输入您的单位/学校~',
						trigger: 'blur'
					}]
				},
				loading: false,
				info: {
					token: '',
					avatar: '',
					name: ''
				}
			}
		},
		created() {

		},
		methods: {
			submitForm(formName) {
				var that = this;
				that.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					}
					let data = {}
					data = that.logininfo
					data.ac = formName
					httpUtil.post('/api/', data, function(res) {
						if (res.status) {
							that.$message({
								showClose: true,
								message: res.msg,
								type: 'success'
							})
							setTimeout(function(){
								that.gologin()
							},3000)
						} else {
							that.$message.error(res.msg)
							return false
						}
					});
			
				})
			},
			loginError() {
				this.logininfo.login_norebots = ''
			},
			loginSuccess() {
				var that = this
				that.$refs['reg_form'].validateField('phone', error => {
					if (!error) {
						that.logininfo.login_norebots = 1;
						let data = {};
						data.ac = "regcode"
						data.phone = that.logininfo.phone
						httpUtil.post('/api/', data, function(res) {
							if (res.status) {
								that.$message({
									showClose: true,
									message: '短信发送成功~',
									type: 'success'
								})
							} else {
								that.$message.error(res.msg)
								that.$refs.login_Check.reset()
							}
				
						});
					} else {
						that.$refs.login_Check.reset()
						return false
					}
				})
				
			},
			gologin() {
				var that = this;
				that.$router.isBack = true
				that.$router.push({
					name: 'index'
				})
			}
		},

	}
</script>
<style>

</style>
