<template>
	<div class="index nobg" v-loading="loading">
		<HeaderInc ref="ref_header" :info="info"></HeaderInc>
		<div class="warp main_body">
			<LeftmenuInc ref="ref_menu" :menu="1"></LeftmenuInc>
			<div class="main_content">
				<h1>>个人信息</h1>
				<div class="main_content_g">
					<el-form ref="reg_form" :model="info" :rules="rules" status-icon label-width="90px"
						class="demo-ruleForm">
						<div class="avatar-upload-box" prop="image">
							<el-upload action="https://www.oda.org.cn/api/Common/upload/"
								:show-file-list="false" :on-success="handleAvatarSuccess" :data="{'token':info.token}"
								:before-upload="beforeAvatarUpload">
								<img v-if="info.image" :src="info.image" class="avatar" />
								<img v-else src="../assets/avater.jpg" class="avatar" />
							</el-upload>
							<span>头像:180*180的jpg格式</span>
						</div>
						<el-form-item label="真实姓名" prop="name">
							<el-input v-model="info.name" type="text" placeholder="请输入真实姓名" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="赛区" prop="division_id">
							<el-select v-model="info.division_id" filterable placeholder="请选择赛区~">
								<el-option v-for="item in division_options" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="身份证" prop="sfcode">
							<el-input v-model="info.sfcode" type="text" placeholder="请输入身份证号" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="职务职称" prop="zhiwu">
							<el-input v-model="info.zhiwu" type="text" placeholder="请输入职务职称" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="单位学校" prop="company">
							<el-input v-model="info.company" type="text" placeholder="请输入详细单位学校"></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="info.email" type="text" placeholder="请输入电子邮箱" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="开户行" prop="bankname">
							<el-input v-model="info.bankname" type="text" placeholder="请详细到具体支行" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="银行卡号" prop="bankcode">
							<el-input v-model="info.bankcode" type="text" placeholder="用于奖金下发" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="行号">
							<el-input v-model="info.banknumber" type="text" placeholder="请详细到支行行号" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="个人简介">
							<el-input v-model="info.jianzhi" rows="5" type="textarea" placeholder="请输入个人成绩和社会兼职介绍" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item>
							<a class="button_1" @click="submitForm">保存</a>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import HeaderInc from '@/components/HeaderInc.vue'
	import LeftmenuInc from '@/components/LeftmenuInc.vue'
	export default {
		name: 'Home',
		components: {
			HeaderInc,
			LeftmenuInc
		},
		props: {},
		data() {
			return {
				info: {
					token: '',
					avatar: '',
					name: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					sfcode: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur'
					}, {
						min: 18,
						max: 18,
						message: '长度在18个数字',
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请输入电子邮箱',
						trigger: 'blur'
					}],
					zhiwu: [{
						required: true,
						message: '请填写职务职称',
						trigger: 'blur'
					}],
					division_id:[{
						required: true,
						message: '请选择赛区',
						trigger: 'blur'
					}],
					image:[{
						required: true,
						message: '请上传形象照',
						trigger: 'blur'
					}],
					company:[{
						required: true,
						message: '请输入工作单位',
						trigger: 'blur'
					}],
					bankname:[{
						required: true,
						message: '请输入开户行',
						trigger: 'blur'
					}],
					bankcode:[{
						required: true,
						message: '请输入银行帐号',
						trigger: 'blur'
					}]
				},
				loading: false,
				division_options:[]
			}
		},
		created() {
			this.getuserdata()
			this.getbasedata()
		},
		methods: {
			submitForm() {
				var that = this;
				if(that.info.division_id == 0){
					this.$message.error('请选择赛区~')
					return false
				}
				that.$refs['reg_form'].validate((valid) => {
					if(valid){
					let data = that.info
					data.ac = 'saveinfo'
					httpUtil.post('/api/', data, function(res) {
						if (res.status == 1) {
							that.$message({
								showClose: true,
								message: '保存成功~',
								type: 'success'
							})
						} else {
							this.$message.error('网络超时~请重试')
						}
					})

					}else{
						this.$message.error('请将信息填写完整~')
						return false
					}
				})
			},
			enterhome() {
				var that = this;
				that.$router.push({
					name: 'Home'
				})
			},
			goBack() {
				var that = this;
				if (that.step == 1) {
					that.$router.isBack = true
					that.$router.push({
						name: 'Index'
					})
				} else {
					that.step = that.step - 1;
				}
			},
			handleAvatarSuccess(res) {
				var that = this
				that.info.avatar = res.data.fullurl
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg'
				const isLt2M = file.size < 1024 * 500

				if (!isJPG) {
					this.$message.error('请上传jpg图片格式~')
				}
				if (!isLt2M) {
					this.$message.error('头像保持500k以下~')
				}
				return isJPG && isLt2M
			},
			getuserdata() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				if (!userinfo.token) {
					that.gologin()
					return false
				}
				let data = {}
				data.ac = 'getuserinfo'
				data.token = userinfo.token
				httpUtil.post('/api/', data, function(res) {
					if (res.id > 0) {
						that.info = res
						that.info.type = parseInt(res.type)
					} else {
						that.gologin()
						return false
					}
				})
			},
			getbasedata() {
				var that = this
				let data = {}
				data.ac = 'division_basedata'
				httpUtil.post('/api/', data, function(res) {
					if (res) {
						that.division_options = res.list
					}
				})
			},
			gologin() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Index'
				})
			},
			gowork(){
				var that = this
				that.$refs['reg_form'].validate((valid) => {
					if(valid){
						var that = this
						that.$router.push({
							name: 'Work'
						})
					}else{
						this.$message.error('请先完成注册~')
						return false
					}
				})
			}
		},

	}
</script>

<style>
	.index.nobg{
		background: #FFFFFF;
	}
	
	.main_body {
		background-color: #F5F5F5;
	}

	.main_content {
		float: right;
		width: 1090px;
		overflow: hidden;
	}

	.main_content h1 {
		font-size: 24px;
		font-weight: 400;
		padding-left: 45px;
		padding-right: 45px;
	}

	.main_content_g {
		width: 960px;
		overflow: hidden;
		background-color: #FFF;
		margin: 20px 45px;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
	}

	.button_2 {
		display: block;
		width: 100px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		border-radius: 15px;
		border: #bd9639 1px solid;
		font-size: 14px;
		font-weight: 400;
		color: #bd9639;
		cursor: pointer;
	}

	.button_1 {
		display: block;
		width: 100px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		font-size: 14px;
		font-weight: 400;
		background-color: #bd9639;
		color: #FFF;
		cursor: pointer;
	}

	.avatar-upload-box {
		width: 160px;
		height: 160px;
		display: block;
		margin: 20px auto;
	}

	.avatar-upload-box img {
		width: 120px;
		height: 120px;
		display: block;
		border-radius: 60px;
	}

	.avatar-upload-box span {
		font-size: 12px;
		color: #bbbbbb;
	}
</style>
