<template>
	<div class="index" v-loading="loading">

		<HeaderInc ref="ref_header" :info="info"></HeaderInc>

		<div class="login_bg">
			<div class="login">
				<div class="login_box">
					<div class="login_title">
						<h1>评审专家登录</h1>
						<span>您可以直接输入手机号和短信验证码登录</span>
					</div>
					<el-form ref="login_form" :model="logininfo" :rules="rules" status-icon label-width="80px"
						class="demo-ruleForm">
						<el-form-item label="手机号" prop="phone">
							<el-input v-model="logininfo.phone" type="text" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="验证" prop="login_norebots">
							<SliderCheck ref="login_Check" :successFun="loginSuccess" :errorFun="loginError">
							</SliderCheck>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
							<el-input v-model="logininfo.code" type="text" autocomplete="off"></el-input>
						</el-form-item>
						
						<el-form-item>
							<a class="login_buttton" @click="submitForm('login_form')">登录</a>
							<a class="reg_buttton" @click="goreg">注册</a>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import SliderCheck from '@/components/SliderCheck.vue'
	import HeaderInc from '@/components/HeaderInc.vue'
	export default {
		name: 'Index',
		components: {
			SliderCheck,
			HeaderInc
		},
		props: {},
		data() {
			const validatephone = (rule, value, callback) => {
				setTimeout(() => {
					const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
					if ((!reg.test(value)) && value != '') {
						callback(new Error('请输入正确的电话号码'));
					} else {
						callback();
					}
				}, 1000)
			}
			return {
				logininfo: {},
				reginfo: {},
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validatephone,
							trigger: 'blur'
						},
					],
					login_norebots: [{
						required: true,
						message: '请过人机滑块验证',
						trigger: 'blur'
					}, ],
					code: [{
						required: true,
						message: '请输入验证码~',
						trigger: 'blur'
					}, ]
				},
				loading: false,
				info: {
					token: '',
					avatar: '',
					name: ''
				}
			}
		},
		created() {
			this.relogin();
		},
		methods: {
			submitForm(formName) {
				var that = this;
				that.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					}
					let data = {}
					data = that.logininfo
					data.ac = formName
					httpUtil.post('/api/', data, function(res) {
						localStorage.setItem('weekinfo', JSON.stringify(res));
						if (res.token) {
							that.enterhome()
						} else {
							that.$message.error(res.msg)
							return false
						}
					});

				})
			},
			enterhome() {
				var that = this;
				that.$router.push({
					name: 'Home'
				})
			},
			// 滑块验证成功回调
			loginSuccess() {
				var that = this
				that.$refs['login_form'].validateField('phone', error => {
					if (!error) {
						that.logininfo.login_norebots = 1;
						let data = {};
						data.ac = "sendcode"
						data.phone = that.logininfo.phone
						httpUtil.post('/api/', data, function(res) {
							if (res.status) {
								that.$message({
									showClose: true,
									message: '短信发送成功~',
									type: 'success'
								})
							} else {
								that.$message.error(res.msg)
								that.$refs.reg_Check.reset()
							}
				
						});
					} else {
						that.$refs.reg_Check.reset()
						return false
					}
				})
				
			},
			// 滑块验证失败回调
			loginError() {
				this.logininfo.reg_norebots = ''
			},
			relogin() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				let data = {}
				data.token = userinfo.token;
				data.ac = 'relogin'
				httpUtil.post('/api/', data, function(res) {
					localStorage.setItem('weekinfo', JSON.stringify(res));
					if (res.token) {
						if (res.status == 1) {
							that.enterhome();
						} else {
							that.enterinfo();
						}
					}
				})
			},
			goreg(){
				var that = this
				that.$router.push({
					name: 'Reg'
				})
			}
		},

	}
</script>
<style>
	.index {
		width: 100%;
		height: 100vh;
		overflow-x: hidden;
		background: url(../assets/bg.jpg) no-repeat center;
		background-size: cover;
	}

	.warp {
		width: 1280px;
		margin: 0 auto;
		overflow: hidden;
	}
	
	
	.login_bg{
		width: 1280px;
		height: 561px;
		background: url(../assets/loginbox.png) no-repeat center;
		position: relative;
		left: 50%;
		top: 50%;
		margin-left: -640px;
		margin-top: -380px;
	}
	
	.login {
		width: 400px;
		height: 480px;
		padding: 40px;
		background-color: #FFFFFF;
		overflow: hidden;
	}

	.login_title {
		width: 100%;
		overflow: hidden;
		margin-bottom: 20px;
	}
	
	.login_title h1 {
		font-size: 28px;
		font-weight: 400;
		color: #1A1A1A;
		line-height: 24px;
	}
	
	.login_title span {
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		line-height: 24px;
	}

	.login_buttton {
		width: 310px;
		height: 50px;
		background: #bd9639;
		display: block;
		color: #FFF;
		border-radius: 25px;
		text-align: center;
		line-height: 50px;
		font-weight: 400;
		font-size: 18px;
		cursor: pointer;
	}

	.reg_buttton {
		width: 308px;
		height: 48px;
		border: #bd9639 1px solid;
		display: block;
		color: #bd9639;
		border-radius: 25px;
		text-align: center;
		line-height: 48px;
		font-weight: 400;
		font-size: 18px;
		margin-top: 10px;
		cursor: pointer;
	}

	.el-color-999 {
		color: #999999;
	}

	.el-color-red {
		color: #bd9639;
	}
	
	.help {
		display: block;
		position: absolute;
		right: 50px;
		top: 60px;
		color: red;
		font-size: 13px;
		cursor: pointer;
	}
</style>
