import axios from 'axios'

/**
 * Get请求
 */
export function get(url, callback){
    axios.get(url)
    .then(function (response) {
        callback(response.data,true)
    })
    .catch(function (error) {
        console.log(error)
        callback(null,false)
    })
}

export function post(url,data,callback){
	axios.post(url,data, {
		timeout: 15000,
		headers: {
			'Content-Type': 'multipart/form-data',
			}
	})
	.then(function (response) {
		callback(response.data,true)
	})
	.catch(function (error) {
		//console.log(error)
		//callback(null,false)
	})
}

export function formatDate(date, fmt) {
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds()
	}
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
		}
	}
	return fmt
}

function padLeftZero(str) {
	return ('00' + str).substr(str.length)
}

export default {
    get,post,formatDate
}