import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import Work from '../views/Work.vue'
import Viewwork from '../views/Viewwork.vue'
import Tongji from '../views/Tongji.vue'
import Reg from '../views/Reg.vue'


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Reg',
    name: 'Reg',
    component: Reg
  },
  {
    path: '/Work',
    name: 'Work',
    component: Work
  },
  {
    path: '/Viewwork',
    name: 'Viewwork',
    component: Viewwork
  },
  {
    path: '/Tongji',
    name: 'Tongji',
    component: Tongji
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
