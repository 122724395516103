<template>
	<div class="index nobg" v-loading="loading">
		<HeaderInc ref="ref_header" :info="info"></HeaderInc>
		<div class="warp main_body">
			<LeftmenuInc ref="ref_menu" menu="3"></LeftmenuInc>
			<div class="main_content">
				<h1>>赛区数据</h1>
				<div class="main_content_g">
					<div class="data_tool">
						<el-form ref="year_form">
							<el-form-item label="年度数据">
								<el-date-picker v-model="data_year" type="year" format="YYYY" :validate-event="false"
									placeholder="请选择"></el-date-picker>
								<el-button type="primary" @click="getTongji">查询</el-button>
							</el-form-item>
						</el-form>
					</div>
					<el-table :data="tableData" border show-summary style="width: 100%">
						<el-table-column label="名称" width="300">
							<template #default="scope">
								{{school_list[zsh(scope.row.school_id)]}}
							</template>
						</el-table-column>
						<el-table-column prop="num1" label="命题" width="100" />
						<el-table-column prop="num2" label="非命题" width="100" />
						<el-table-column prop="num3" label="合计" width="100" />
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import HeaderInc from '@/components/HeaderInc.vue'
	import LeftmenuInc from '@/components/LeftmenuInc.vue'
	export default {
		name: 'viewwork',
		components: {
			HeaderInc,
			LeftmenuInc
		},
		props: {},
		data() {
			return {
				loading: true,
				info: {
					token: '',
					avatar: '',
					name: ''
				},
				data_year:'2021',
				tableData:[],
				school_list:[]
			}
		},
		created() {
			this.getuserdata()
		},
		updated() {

		},
		activated() {
		},
		methods: {
			getuserdata() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				if (!userinfo.token) {
					that.gologin()
					return false
				}
				let data = {}
				data.ac = 'getuserinfo'
				data.token = userinfo.token
				httpUtil.post('/api/', data, function(res) {
					if (res.id > 0) {
						that.info = res
						that.loading = false
						that.getTongji()
					} else {
						that.gologin()
						return false
					}
				})
			},
			gologin() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Index'
				})
			},
			qiege(str) {
				if (str) {
					return str.split('|')
				} else {
					return [];
				}
			},
			zsh(str) {
				return parseInt(str);
			},
			getTongji(){
				var that = this
				let data = {}
				data.ac = 'tongji'
				data.token = that.info.token
				data.year = that.data_year
				httpUtil.post('/api/', data, function(res) {
					that.tableData = res.tjlist
					that.school_list = res.school_list
				})	
			}
		},

	}
</script>

<style>

</style>
