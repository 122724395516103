<template>
	<div class="index nobg" v-loading="loading">
		<HeaderInc ref="ref_header" :info="info"></HeaderInc>
		<div class="warp main_body">
			<LeftmenuInc ref="ref_menu" :menu="2"></LeftmenuInc>
			<div class="main_content">
				<h1>>作品评审 <span>(请各位评委一定要点击【评审】按钮进入打分，不要通过【查看】页面进入打分！【查看】页面是方便评委们对已经打分的作品进行查看和调整。)</span></h1>
				<div class="main_content_g">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="id" label="任务ID" width="100" />
						<el-table-column prop="match_title" label="赛道" width="200" />
						<el-table-column prop="title" label="轮次" width="200" />
						<el-table-column prop="startday" label="开始时间" width="150" />
						<el-table-column prop="endday" label="结束时间" width="150" />
						<el-table-column label="状态">
							<template #default="scope">
								<span>{{status_option[scope.row.status]}}</span>
							</template>
						</el-table-column>
						<el-table-column label="进度">
							<template #default="scope">
								<span v-show="canvivew('1')">{{scope.row.num}}/{{scope.row.maxnum}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="posttime" label="提交时间" width="180"></el-table-column>
						<el-table-column fixed="right" label="操作" width="240">
							<template #default="scope">
								<a class="button_1 mini"
									v-show="!scope.row.posttime && scope.row.num != scope.row.maxnum && scope.row.judges_id == info.id"
									@click="startps(scope.$index)">评审</a>
								<a class="button_2 mini" v-show="!scope.row.posttime"
									@click="viewwork(scope.$index)">查看</a>
								<a class="button_2 mini" v-show="!scope.row.posttime && scope.row.num == scope.row.maxnum && scope.row.judges_id == info.id"
									@click="endps(scope.$index)">提交</a>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>

		<el-dialog v-model="viewbox_show" width="60%" :title="'评审进度:'+work_index+'/'+pslist.length+' 当前作品所属赛道:'+wtype_option[year][viewbox.wtype]">
			<el-row>
				<el-col :span="15">
					<div v-show="viewbox.docfile" class="doclist">
						<a v-for="(item,index) in qiege(viewbox.docfile)" :key="index" :download="index" target="_blank" :href="item">下载论文1</a>
					</div>
					<div v-show="viewbox.image" class="imglist">
						<img :src="viewbox.image+'?x-oss-process=image/resize,w_800,m_lfit'" />
					</div>
					<div v-show="viewbox.images" class="imglist">
						<img v-for="(item,index) in qiege(viewbox.images)" :key="index" :src="item+'?x-oss-process=image/resize,w_800,m_lfit'" />
					</div>
					<div v-show="viewbox.videofile" class="video-box">
						<span>未按格式要求上传的音频、视频作品无法正常播放，需先<a :href="viewbox.videofile" download="video" target="_blank">下载</a>查阅</span>
						<span v-show="viewbox.fujian">数字附件可 <a :href="viewbox.fujian" download="fujian" target="_blank">下载</a> </span>
						<video :src="viewbox.videofile" controls="controls"></video>
					</div>
				</el-col>
				<el-col :span="1">
				</el-col>
				<el-col :span="8">
					<h1>{{viewbox.title}}</h1>
					<p>
						{{viewbox.profile}}
						<br>
						{{viewbox.jxtxt}}
					</p>
					<el-divider>
						评审须知
					</el-divider>
					<p style="white-space: pre-wrap;">
						{{psinfo.xuzhi}}
					</p>
					
					<el-form ref="mark_form" status-icon label-width="70px" class="demo-ruleForm">
						<el-form-item v-for="(item,index) in guize" :label="item.name">
							<el-input-number v-model="zpdafen['grade_'+(index+1)]" :name="'grade_'+(index+1)" :min="0.01"
								:max="zsh(item.num)" placeholder="0.01" :controls="false" />
						</el-form-item>
						<el-form-item label="总分">
							{{floatint(zpdafen.grade_1) + floatint(zpdafen.grade_2)  + floatint(zpdafen.grade_3)  + floatint(zpdafen.grade_4)  + floatint(zpdafen.grade_5)}}
						</el-form-item>
						<el-form-item label="评语">
							<el-input v-model="zpdafen['txt']" type="textarea"></el-input>
						</el-form-item>
						<el-form-item>
							<a class="button_1" @click="dafen">保存</a>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</el-dialog>

	</div>
</template>

<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import HeaderInc from '@/components/HeaderInc.vue'
	import LeftmenuInc from '@/components/LeftmenuInc.vue'
	export default {
		name: 'Work',
		components: {
			HeaderInc,
			LeftmenuInc
		},
		props: {},
		data() {
			return {
				loading: true,
				info: {
					token: '',
					avatar: '',
					name: ''
				},
				tableData: [],
				status_option: ['进行中', '结束'],
				wtype_option:[],
				viewbox_show: false,
				viewbox: {},
				zpdafen: {
					grade_1:0.00,
					grade_2:0.00,
					grade_3:0.00,
					grade_4:0.00,
					grade_5:0.00
				},
				work_index: 0,
				pslist: [],
				roles:[],
				year:2024
			}
		},
		created() {
			this.getuserdata();
			this.getfmtoption();
		},
		updated() {

		},
		methods: {
			getfmtoption(){
				var that = this
				let data = {}
				data.ac = 'getfmtcer'
				httpUtil.post('/api/', data, function(res) {
					that.wtype_option = res;
				})
			},
			getuserdata() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				if (!userinfo.token) {
					that.gologin()
					return false
				}
				let data = {}
				data.ac = 'getuserinfo'
				data.token = userinfo.token
				httpUtil.post('/api/', data, function(res) {
					if (res.id > 0) {
						that.info = res
						if(that.info.roles){
							that.roles = that.info.roles.split(",")
						}
						that.loading = false
						that.getworklist()
					} else {
						that.gologin()
						return false
					}
				})
			},
			gologin() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Index'
				})
			},
			gohome() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Home'
				})
			},
			getworklist() {
				var that = this
				let data = {}
				data.ac = "getworklist"
				data.token = that.info.token
				httpUtil.post('/api/', data, function(res) {
					if (res) {
						that.tableData = res.worklist
						that.division_list = res.division_list
					} else {
						that.tableData = []
					}
				})
			},
			startps(index) {
				var that = this
				let data = {}
				data.ac = "getzpxx"
				data.token = that.info.token
				data.rid = that.tableData[index].id
				httpUtil.post('/api/', data, function(res) {
					if (res.pslist.length > 0) {
						that.pslist = res.pslist
						that.psinfo = res.psinfo[0]
						that.guize = JSON.parse(that.psinfo.txt)
						that.startwork()
					}
				})
			},
			startwork() {
				var that = this
				let over = true
				for (let i in that.pslist) {
					if (that.pslist[i].grade_1 == 0) {
						that.work_index = i
						over = false
						break
					}
				}
				
				if (over) {
					that.work_index = that.pslist.length - 1
					that.$message({
						showClose: true,
						message: '已经评审完毕,如果需修改评分,请点击查看',
						type: 'success'
					})
				} else {
					that.viewbox = that.pslist[that.work_index];
					that.viewbox_show = true
				}

			},
			qiege(str) {
				if (str) {
					return str.split('|')
				} else {
					return [];
				}
			},
			zsh(str) {
				return parseInt(str);
			},
			floatint(str){
				return parseFloat(str);
			},
			dafen() {
				var that = this
				that.loading = true
				let data = JSON.parse(JSON.stringify(that.zpdafen))
				data.ac = 'makesave'
				data.id = that.viewbox.id
				data.token = that.info.token
				httpUtil.post('/api/', data, function(res) {
					if (res.status == 1) {
						that.$message({
							showClose: true,
							message: res.msg,
							type: 'success'
						})
						
						that.zpdafen = {grade_1:0.00,grade_2:0.00,grade_3:0.00,grade_4:0.00,grade_5:0.00}
						
						if (that.pslist[parseInt(that.work_index) + 1]) {
							that.work_index = parseInt(that.work_index) + 1
							that.viewbox = that.pslist[that.work_index];
						} else {
							that.getworklist()
							that.viewbox_show = false
						}

					} else {
						that.$message.error(res.msg)
					}
					that.loading = false
				})
			},
			endps(index) {
				var that = this
				let data = {}
				data.ac = "overps"
				data.token = that.info.token
				data.rid = that.tableData[index].id
				httpUtil.post('/api/', data, function(res) {
					if (res.status == 1) {
						that.getworklist()
						that.$message({
							showClose: true,
							message: res.msg,
							type: 'success'
						})
					} else {
						that.$message.error(res.msg)
					}
				})
			},
			viewwork(index) {
				var that = this
				let data = that.tableData[index]
				if (data.posttime == null) {
					that.$router.push({
						name: 'Viewwork',
						query: {
							rid: that.tableData[index].id,
						}
					})
				}
			},
			canvivew(rank){
				var that = this
				
				if((rank == 1 || rank == 5) && that.info.type == '0'){
					return true
				}
				
				if(that.roles.indexOf(rank) >= 0){
					return true
				}else{
					return false
				}
			}
		},

	}
</script>

<style>
	.workadd {
		width: 92px;
		height: 92px;
		display: block;
		margin: 30px auto;
		background: url(../assets/ico_6.png) no-repeat center;
		cursor: pointer;
	}

	.mini {
		width: 60px;
		display: inline-block;
		margin: 0 5px;
	}

	.video-box {
		max-width: 100%
	}

	.video-box video {
		max-width: 100% !important;
		display: block;
	}

	.doclist a {
		display: block;
	}

	.imglist img {
		width: 100%;
		display: block;
	}
	
	.main_content h1 span{
		font-size: 12px;
		color: red;
	}
</style>
