<template>
	<div class="left_mune">
		<ul>
			<li :class="menu==1?'active':''" @click="gopage(1)">
				<img src="../assets/ico_3.png" />
				个人信息
			</li>
			<li :class="menu==2?'active':''" @click="gopage(2)">
				<img src="../assets/ico_4.png"  />
				作品评审
			</li>
			<li :class="menu==3?'active':''" @click="gopage(3)">
				<img src="../assets/ico_4.png" />
				赛区数据
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'LeftmenuInc',
		props: {
			menu : '',
		},
		data() {
			return {
			}
		},
		methods: {
			gopage(str) {
				var that = this
				let pagearr = ['Home','Home','Work','Tongji']
				
				if(that.menu == 1 && str == 2){
					that.$parent.gowork()
					return false
				}
				
				if(that.menu > str){
					that.$router.isBack = true
				}
				if (str != that.menu) {
					that.$router.push({
						name: pagearr[str]
					})
				}
			}
		}
	}
</script>

<style>
	.left_mune {
		width: 190px;
		float: left;
		overflow: hidden;
		margin-top: 2px;
		background-color: #FFF;
	}

	.left_mune ul {
		margin: 0;
		padding: 0;
		display: block;
	}

	.left_mune li {
		width: 168px;
		height: 82px;
		text-align: center;
		list-style: none;
		display: block;
		overflow: hidden;
		border-left: 4px solid #4e4e4e;
		margin: 18px 0 18px 18px;
		font-size: 16px;
		font-weight: 400;
		color: #4e4e4e;
		cursor: pointer;
		line-height: 82px;
	}

	.left_mune li img {
		width: 30px;
		height: 30px;
		display: inline-block;
		margin-bottom: -5px;
	}

	.left_mune li.active {
		border-left: 4px solid #a17c23;
		background-color: #ba953a;
		color: #fff;
	}
</style>
