<template>
	<div class="header">
		<div class="warp">
			<a href="/" class="logo"></a>
			<ul class="nav">
				<li @click="gopage('https://www.oda.org.cn')" class="active">
					东方设计展
				</li>
			</ul>

			<ul class="tool" v-show="!info.token">
				<li @click="gopage('https://match.oda.org.cn')">
					作品上传
				</li>
				<li @click="gopage('https://college.oda.org.cn')">
					院校管理
				</li>
				<li @click="gopage('Index')" class="active">
					评审登录
				</li>
			</ul>

			<ul class="tool" v-show="info.token">
				<li>
					<img v-if="info.avatar" :src="info.avatar" />
					<img v-else src="../assets/avater.jpg" />
					{{info.name}}专家，您好
				</li>
				<li @click="logout" class="logout">
					退出登录
				</li>
				<li @click="gopage('https://www.oda.org.cn')">
					首页
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HeaderInc',
		props: {
			info: {
				token: '',
				avatar: '',
				name: ''
			}
		},
		data() {
			return {
			}
		},
		methods: {
			logout() {
				var that = this
				localStorage.removeItem("weekinfo");
				that.$router.isBack = true
				that.$router.push({
					name: 'Index'
				})
			},
			gopage(str) {
				var that = this
				if (str.indexOf('https') >= 0) {
					window.location.href = str
				} else {
					that.$router.push({
						name: str
					})
				}
			}
		}
	}
</script>

<style>
	.header {
		width: 100%;
		height: 100px;
		display: block;
		overflow: hidden;
	}

	.header a.logo {
		width: 279px;
		height: 46px;
		margin-top: 27px;
		display: block;
		background: url(../assets/logo.png) no-repeat center;
		background-size: cover;
		float: left;
		position: relative;
		margin-left: 7%;
	}

	.header ul.nav,
	.header ul.tool {
		width: auto;
		height: 100px;
		display: block;
		float: left;
		margin: 0;
	}

	.header ul.tool {
		float: right;
		margin-right: 5%;
		vertical-align: middle;
	}

	.header ul.nav li,
	.header ul.tool li {
		width: auto;
		height: 40px;
		line-height: 40px;
		display: inline-block;
		list-style: none;
		margin: 30px 15px;
		cursor: pointer;
		vertical-align: middle;

	}

	.header ul.nav li.active {
		color: #d3a546;
		border-bottom: 2px solid #d3a546;
	}

	.header ul.tool li.active {
		color: #d3a546;
	}

	.header ul.tool li img {
		width: 40px;
		height: 40px;
		display: inline-block;
		vertical-align: middle;
	}

	.logout {
		background: url(../assets/ico_5.png) no-repeat left;
		background-size: auto 18px;
		padding-left: 24px;
	}
</style>
