<template>
	<div class="index nobg" v-loading="loading">
		<HeaderInc ref="ref_header" :info="info"></HeaderInc>
		<div class="warp main_body">
			<LeftmenuInc ref="ref_menu" :menu="2"></LeftmenuInc>
			<div class="main_content">
				<h1>>{{psinfo.title}} > 查看 <a class="button_2 mini frb" @click="backwork">返回</a></h1>
				<div class="main_content_g">
					<el-row :gutter="20">
						<template v-for="(item,index) in nowlist">
							<el-col :span="6" @click="viewitem(index)" style="cursor: pointer;">
								<div class="grid-content bg-purple">
									<el-image style="width: 225px; height: 245px" hide-on-click-modal :src="item.image+'?x-oss-process=image/resize,h_245,m_lfit'"
										fit="scale-down" lazy>
									</el-image>
								</div>
								<div class="info">
									<h4>{{item.title}}</h4>
									<p v-show="canvivew('5')">
										总分:{{floatint(item.grade_1) + floatint(item.grade_2) + floatint(item.grade_3) + floatint(item.grade_4) + floatint(item.grade_5)}}
									</p>
								</div>
							</el-col>
						</template>
					</el-row>
				</div>

				<el-pagination background layout="prev, pager, next" :total="pslist.length" :page-size="16"
					@current-change="setpage">
				</el-pagination>
			</div>
		</div>

		<el-dialog v-model="viewbox_show" width="60%" :title="viewbox.title+' 所属赛道:'+wtype_option[year][viewbox.wtype]" :before-close="handclose">
			<el-row>
				<el-col :span="15">
					<div v-show="viewbox.docfile" class="doclist">
						<a v-for="(item,index) in qiege(viewbox.docfile)" :key="index" :href="item" :download="index" target="_blank">下载论文1</a>
					</div>
					<div v-show="viewbox.image" class="imglist">
						<img :src="viewbox.image+'?x-oss-process=image/resize,w_800,m_lfit'" />
					</div>
					<div v-show="viewbox.images" class="imglist">
						<img v-for="(item,index) in qiege(viewbox.images)" :key="index" :src="item+'?x-oss-process=image/resize,w_800,m_lfit'" />
					</div>
					<div v-show="viewbox.videofile" class="video-box">
						<span>未按格式要求上传的音频、视频作品无法正常播放，需先<a :href="viewbox.videofile" download="video" target="_blank">下载</a>查阅</span>
						<span v-show="viewbox.fujian">数字附件可 <a :href="viewbox.fujian" download="fujian" target="_blank">下载</a> </span>
						<video :src="viewbox.videofile" controls="controls"></video>
					</div>
				</el-col>
				<el-col :span="1">
				</el-col>
				<el-col :span="8">
					<h1>{{viewbox.title}}</h1>
					<p v-show="canvivew('3')">投稿人：{{viewbox.author}}</p>
					<p v-show="canvivew('2')">学校：{{school_list[zsh(viewbox.school_id)]}}</p>
					<p style="white-space: pre-wrap;">
						{{viewbox.profile}}
					</p>

					<el-divider>
						评审须知
					</el-divider>
					
					<p style="white-space: pre-wrap;">
						{{psinfo.xuzhi}}
						<span class="xdts">请评委输入分数后记得保存</span>
					</p>
					
					<el-form ref="mark_form" status-icon label-width="70px" class="demo-ruleForm">
						<el-form-item v-for="(item,index) in guize" :label="item.name">
							<el-input-number v-model="zpdafen['grade_'+(index+1)]" :name="'grade_'+(index+1)" :min="0.01"
								:max="zsh(item.num)" placeholder="0.01" :controls="false" />
						</el-form-item>
						<el-form-item label="总分">
							{{floatint(zpdafen.grade_1) + floatint(zpdafen.grade_2)  + floatint(zpdafen.grade_3)  + floatint(zpdafen.grade_4)  + floatint(zpdafen.grade_5)}}
						</el-form-item>
						<el-form-item label="评语">
							<el-input v-model="viewbox.txt" type="textarea"></el-input>
						</el-form-item>
						<el-form-item>
							<a class="button_1" @click="dafen">保存</a>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</el-dialog>

	</div>
</template>

<script>
	import httpUtil from '@/util/HttpRequestUtil'
	import HeaderInc from '@/components/HeaderInc.vue'
	import LeftmenuInc from '@/components/LeftmenuInc.vue'
	export default {
		name: 'viewwork',
		components: {
			HeaderInc,
			LeftmenuInc
		},
		props: {},
		data() {
			return {
				loading: true,
				info: {
					token: '',
					avatar: '',
					name: ''
				},
				viewbox_show: false,
				viewbox: {},
				zpdafen: {
					grade_1:0.00,
					grade_2:0.00,
					grade_3:0.00,
					grade_4:0.00,
					grade_5:0.00
				},
				work_index: 0,
				pslist: [],
				guize: [],
				psinfo: {},
				page: 1,
				nowlist: [],
				roles:[],
				school_list:[],
				wtype_option:[],
				year:2024
			}
		},
		created() {
			this.getuserdata();
			this.getfmtoption();
		},
		updated() {

		},
		activated() {
			this.rid = this.$route.query.rid
			this.startps()
		},
		methods: {
			getfmtoption(){
				var that = this
				let data = {}
				data.ac = 'getfmtcer'
				httpUtil.post('/api/', data, function(res) {
					that.wtype_option = res;
				})
			},
			getuserdata() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				if (!userinfo.token) {
					that.gologin()
					return false
				}
				let data = {}
				data.ac = 'getuserinfo'
				data.token = userinfo.token
				httpUtil.post('/api/', data, function(res) {
					if (res.id > 0) {
						that.info = res
						if(that.info.roles){
							that.roles = that.info.roles.split(",")
						}
						that.loading = false
					} else {
						that.gologin()
						return false
					}
				})
			},
			gologin() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Index'
				})
			},
			gohome() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Home'
				})
			},
			startps() {
				var that = this
				var userinfo = JSON.parse(localStorage.getItem('weekinfo')) || [];
				let data = {}
				data.ac = "getzpxx"
				data.token = userinfo.token
				data.rid = that.rid
				httpUtil.post('/api/', data, function(res) {
					if (res.pslist.length > 0) {
						that.pslist = res.pslist
						that.nowlist = that.pslist.slice(0, 16)
						that.psinfo = res.psinfo[0]
						that.guize = JSON.parse(that.psinfo.txt)
						that.school_list = res.school_list
					}
				})
			},
			qiege(str) {
				if (str) {
					return str.split('|')
				} else {
					return [];
				}
			},
			zsh(str) {
				return parseInt(str);
			},
			floatint(str){
				return parseFloat(str);
			},
			dafen() {
				var that = this
				that.loading = true
				let data = JSON.parse(JSON.stringify(that.zpdafen))
				data.ac = 'makesave'
				data.id = that.viewbox.id
				data.token = that.info.token
				httpUtil.post('/api/', data, function(res) {
					if (res.status == 1) {
						that.$message({
							showClose: true,
							message: '修改成功~',
							type: 'success'
						})
						that.viewbox.grade_1 = data.grade_1
					} else {
						that.$message.error(res.msg)
					}
					that.loading = false
				})
			},
			backwork() {
				var that = this
				that.$router.isBack = true
				that.$router.push({
					name: 'Work'
				})
			},
			viewitem(index) {
				var that = this
				that.viewbox = that.nowlist[index]
				if(that.info.type == '0' || that.canvivew('5')){
					that.zpdafen.grade_1 = that.floatint(that.viewbox.grade_1)
					that.zpdafen.grade_2 = that.floatint(that.viewbox.grade_2)
					that.zpdafen.grade_3 = that.floatint(that.viewbox.grade_3)
					that.zpdafen.grade_4 = that.floatint(that.viewbox.grade_4)
					that.zpdafen.grade_5 = that.floatint(that.viewbox.grade_5)
				}
				
				if(that.info.type == '0' || that.canvivew('4')){
					that.viewbox_show = true
				}
			},
			setpage(e) {
				var that = this
				that.page = e
				let start = (that.page - 1) * 16
				that.nowlist = that.pslist.slice(start, start + 16)
			},
			canvivew(rank){
				var that = this
				if((rank == 1 || rank == 5) && that.info.type == '0'){
					return true
				}
				
				if(that.roles.indexOf(rank) >= 0){
					return true
				}else{
					return false
				}
			},
			handclose(done){
				var that = this
				done()
				that.zpdafen = {grade_1:0.00,grade_2:0.00,grade_3:0.00,grade_4:0.00,grade_5:0.00}
			}
		},

	}
</script>

<style>
	.frb {
		float: right;
	}

	.bg-purple {
		background: #d3dce6;
	}
	.xdts{
		color: red;
	}
</style>
