<template>
	<router-view v-slot="{ Component }">
		<transition :name="transitionName">
			<keep-alive>
				<component :is="Component" />
			</keep-alive>
		</transition>
	</router-view>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			transitionName: 'slide-right'
		}
	},
	watch: {
		'$route'() {
			let isBack = this.$router.isBack
			if (isBack) {
				this.transitionName = 'slide-right'
			} else {
				this.transitionName = 'slide-left'
			}
			this.$router.isBack = false
		}
	}
}
</script>

<style>
html,body{
	width: 100%;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	background-color: #FFFFFF;
	font-family:"Microsoft YaHei","微软雅黑","Microsoft JhengHei","华文细黑","STHeiti","MingLiu";
}
#app {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
}

.Router {
	position: absolute;
	width: 100%;
	transition: all .5s ease;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
	height: 100%;
	will-change: transform;
	transition: all 500ms;
	position: absolute;
	backface-visibility: hidden;
	perspective: 1000;
}
.slide-right-enter,.slide-right-enter-active{
animation: bounce-left-in 500ms;
}
.slide-right-leave-active {
animation: bounce-left-out 500ms;
}
.slide-left-enter, .slide-left-enter-active{
animation: bounce-right-in 500ms;
}
.slide-left-leave-active {
animation: bounce-right-out 500ms;
}

.jamesbase-enter-active,
.jamesbase-leave-active {
  transition: all 0.5s ease;
}

.jamesbase-enter-from,
.jamesbase-leave-to {
	opacity: 0;
	transform: translateY(-50px);
}

@keyframes bounce-right-in {
	0% {
	transform: translate3d(100%, 0, 0);
	}
	100% {
	transform: translate3d(0px, 0, 0);
	}
}
@keyframes bounce-right-out {
	0% {
	transform: translate3d(0, 0, 0);
	}
	100% {
	transform: translate3d(-100%, 0, 0);
	}
}
@keyframes bounce-left-in {
	0% {
	transform: translate3d(-100%, 0, 0);
	}
	100% {
	transform: translate3d(0px, 0, 0);
	}
}
@keyframes bounce-left-out {
	0% {
	transform: translate3d(0, 0, 0);
	}
	100% {
	transform: translate3d(100%, 0, 0);
	}
}
</style>
